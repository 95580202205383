   /** @jsx jsx */
   import { jsx, Box, Container, Flex, Image, Badge, Heading, Text, Grid } from 'theme-ui';
   import { graphql } from 'gatsby';
   import Layout from 'components/subLayout';
   import { SRLWrapper } from "simple-react-lightbox";
   import SearchEngineOptimization from 'components/seo';
   import SimpleReactLightbox from 'simple-react-lightbox'
   import { StickyProvider } from '../contexts/app/app.provider';

   const Product = ({ data }) => {
    const { contentfulProductPage } = data || {};
    const cardBox = contentfulProductPage || {};
    const { type, location, name, id, cardFeatures, description, image, imageGallery } = cardBox || {}; 
    const gallery = imageGallery.images;
    return (
      <SimpleReactLightbox>
        <StickyProvider>
          <Layout mb={80}>
            <SearchEngineOptimization
            title={name}
            description={description.description}
            />
            <Box id="product" as="section" sx={styles.section} key={id} >
              <Box>
              <Box className="bg-image">
                <Image src={image && image.file.url} alt={image.file.fileName}/>
              </Box>
              <Container sx={styles.container}>
                <Box sx={styles.badgeWrap}>
                  <Badge className="cardBadge">{type}</Badge>
                </Box>
                <Box className="location">
                  <Heading sx={styles.heading}>{location}</Heading>
                </Box>
                <Grid columns={[1, 1, 6, 6, 5, 5]}>
                {cardFeatures.map(({ amount, name, image }, i) => (
                  <Box sx={styles.card} key={i}>  
                  <Flex sx={styles.postFooter}>
                    <Image src={image.file.url} alt={image.file.fileName} loading="lazy" sx={styles.postFooter.name}/>
                    <Text sx={styles.postFooter.date}>{amount} {name}</Text>
                  </Flex>
                  </Box>
                ))}
                </Grid>
                <Box className="desc">
                  <Box as="p">{description.description}</Box>
                </Box>
                <Box>
                  <SRLWrapper>
                    <Flex sx={styles.galleryWrapper}>
                      <Grid columns={[2, 3, 3, 3, 4, 4]} >
                        {gallery.map(({file: { url, fileName } }, i) => (
                          <Image src={url} alt={fileName} key={i} loading="lazy"/>
                        ))}
                      </Grid>
                      </Flex>
                    </SRLWrapper>
                </Box>
              </Container>
              </Box>
            </Box>
          </Layout>
        </StickyProvider>
      </SimpleReactLightbox>
     );
   };
   
   export const query = graphql`
    query getProduct($slug: String!){
      contentfulProductPage(slug: {eq: $slug}) {
        id
        location
        slug
        name
        type
        image {
          file {
            fileName
            url
          }
          title
        }
        imageGallery {
          images {
            file {
              url
              fileName
            }
          }
        }
        description {
          description
        }
        cardFeatures {
          amount
          name
          image {
            file {
              url
              fileName
            }
          }
        } 
      }
    }
  `;
  
   export default Product;

   const styles = {
     section:{
      backgroundColor:'#fff',
      overflow: ['hidden', 'initial', null, null, 'hidden'],
      pt: ['150px', '145px'],
      py: [8, null, 9, null, null, 10],    
        '.grid':{
        mt: '10px',
        gap: [20, 20, 20, 20, 30, 45],
     },
     '.bg-image':{
      width:"-webkit-fill-available",
      img:{
        width:'inherit',
        height: '400pt',
        objectFit: 'cover',
      }
     },
     '.desc':{
        paddingBottom:'50px',
        paddingTop:'25px'
     },
     '.amenities':{
       paddingBottom:'50px'
     },
      '.location':{
        color: 'text',
        fontSize: ['12px', '12px', '14px', '14px', '14px',],
        fontWeight:' 700',
        display:'block',
      },
      '.cardBadge':{
        backgroundColor:'#145315',
        borderRadius:'3px',
        marginBottom:'25px',     
        marginTop:'25px',
        fontSize: ['12px', '12px', '14px', '14px', '14px', '14px'],
      },
      '.cardBadges':{
        backgroundColor:'#f2f9f4',
        borderRadius:'3px',
        marginBottom:'25px',     
        marginTop:'25px',
        fontSize: ['12px', '12px', '14px', '14px', '14px', '14px'],
        img:{
          width:'22px'
        }
      }
     },
     galleryWrapper:{
      img:{
        borderRadius: '7px',
        height:'100%',
        width:'100%',
        objectFit:'cover'
      }
     },
     badgeWrap:{
      alignItems: 'center',
      display:'flex',
      flexDirection: ['column', null, null, 'row'],
      justifyContent: ['center', null, null, 'space-between'],
      '.share':{
        position:'relative',
        left:'50%'
     },
     '@media screen and (max-width: 425px)': {
      display:'none',

    },
     },
     heading:{
       marginBottom:'25px',
       fontSize:'18px',
       color: 'heading',
       lineHeight: [1.85, 2.0],
       fontWeight:'semi-bold',
     },
     card: {
      backgroundColor: 'white',
      borderRadius: '20px',
      border:'1px solid rgba(38, 78, 118, 0.1)',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: ['15px 20px', '25px 15px'],
      width:'225px',
      '@media screen and (min-device-width: 320px) and  (max-width: 425px)': {
        width:'100%',
        justifyContent:'space-between',
        alignItem:'center',
        padding:['17px 20px'],
      },
      // m: '0 15px 0',x
      transition: 'all 0.3s',
      '&:hover': {
        boxShadow: '0px 5px 20px rgba(38,78,118,0.15)',
      },
    },  
     postFooter: {
      width: '100%',
      justifyContent: 'center',
      '@media screen and (min-device-width: 320px) and  (max-width: 425px)': {
        justifyContent:'space-between',
      },
      alignItems:'center',
      img: {
       width:'34px',
       marginRight:'35px',
      },
      date: {
        fontSize: ['14px', null, 2],
        fontWeight: 400,
        lineHeight: 1.5,
      },
    },

   }
   